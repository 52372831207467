@import "global";

body, #root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.spin {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.underline {
  position: relative;
}

.underline:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  background-color: $_border;
  width: 100%;
}
