$_transparent: transparent;
$_white: #fff;
$_primary: #0452DD;
$_blue0: #C7E0FF;
$_blue1: #309BF7;
$_blue2: #4a5c86;
$_blue3: #309bf7;
$_blue4: #5A9EF7;
$_green: #1DC002;
$_green1: #46BB36;
$_black1: #3C3C3C;
$_black: #333;
$_grey0: #ccc;
$_grey1: #B1B1B1;
$_grey2: #a5a5a5;
$_grey3: #999;
$_grey4: #f8f8f8;
$_grey5: #e5e5e5;
$_grey6: #eaf5fe;
$_grey7: #eaeaea;
$_grey8: #f3f3f3;
$_grey9: #666;
$_grey10: #ddd;
$_grey11: #f6f6f6;
$_grey12: #b7b7b7;
$_yellow: #FFCC00;
$_red0: #FD5F24;
$_red1: #DF3D3D;
$_red2: #FF3E3E;
$_red3: #ED0000;

$_border:$_grey4
