@import "./color";

$radio: 37.5;

@function px2rem($px) {
  @return $px/$radio + rem;
}

.margin-top {
  margin-top: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}
